<template>
  <div class="w-100 mb-4">
    <div class="card mb-4">
      <div class="card-header pb-0">
        <h6 class="text-uppercase" v-if="user">{{user.type}} details</h6>
      </div>
      <div class="card-body px-4">
        <div class="row">
          <div class="col">
            <ul style="list-style: none" v-if="user">
              <li><span class="font-weight-bold">Name:</span>
                <input type="text" class="form-control" v-model="user.name">
              </li>
              <li><span class="font-weight-bold">Email:</span>
                <input type="text" class="form-control" v-model="user.email">
              </li>
              <li><span class="font-weight-bold">Identification:</span>
                <input type="text" class="form-control" v-model="user.identification">
              </li>
              <li><span class="font-weight-bold">Phone:</span>
                <input type="text" class="form-control" v-model="user.phone">
              </li>
            </ul>
            <div class="w-100">
              <button class="btn btn-success w-100" @click="updateUser">update information user</button>
            </div>
          </div>
          <div class="col">
            <ul style="list-style: none" v-if="user">
              <li><span class="font-weight-bold">Country:</span>
                <input type="text" class="form-control" v-model="user.country">
              </li>
              <li><span class="font-weight-bold">Campaign:</span>
                <input type="text" class="form-control" v-model="user.campaign">
              </li>
              <li class="mt-2"><span class="font-weight-bold">Date Created</span>
                <p class="form-control">{{$filters.formatDate(user.created_at)}}</p>
              </li>
              <li><span class="font-weight-bold">Token:</span>
                <p type="text" class="form-control text-danger " style="font-size: 1.5rem">{{user._id}}</p>
              </li>
              <li class="text-center">
                <p class="font-weight-bold">Url identification file: STATUS {{user_configuration ? user_configuration.status ? 'Approved' : 'Not Approved': 'Not uploaded'}}</p>
                <div class="btn-group" role="group" v-if="user_configuration">
                  <a class="btn-info btn" :href="user_configuration.url_file" target="_blank"> VIEV FILE</a>
                  <button class="btn-danger btn" v-if="!user_configuration.status" @click="deleteFile"> DELETE FILE</button>
                  <button class="btn-success btn" v-if="!user_configuration.status" @click="approveFile"> APPROVE FILE</button>
                </div>
              </li>
            </ul>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <h6 class="text-center">This form will create a transactions automatically</h6>
                <label>Total transactions</label>
                <input type="number" class="form-control" v-model="transactions.total_transactions">
                <label>Total amount</label>
                <input type="number" class="form-control" v-model="transactions.total_amount">
                <div class="w-100 mt-4">
                  <button class="btn btn-success w-100" @click="createTransactions">Create transactions</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="card mb4">
      <table class="table table-bordered table-sm">
        <thead>
        <tr>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Agent</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">amount</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">description</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">reference</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">created_at</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">options</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, key) in array_transactions" :key="key">
          <td>
            <p class="text-uppercase text-xs font-weight-bold mb-0">{{item.agent.name}}</p>
          </td>
          <td>
            <p class="text-uppercase text-xs font-weight-bold mb-0">${{$filters.formatNumber(item.amount)}}</p>
          </td>
          <td>
            <p class="text-uppercase text-xs font-weight-bold mb-0">{{item.description}}</p>
          </td>
          <td>
            <p class="text-uppercase text-xs font-weight-bold mb-0">{{item.reference}}</p>
          </td>
          <td>
            <p class="text-xs font-weight-bold mb-0">{{$filters.formatDate(item.created_at)}}</p>
          </td>
          <td>
            <button class="btn btn-danger btn-sm mb-0" @click="removeTransaction(item._id)">Remove</button>
            <button class="btn btn-success btn-sm mb-0" @click="showModal(item)">Update</button>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <h5 class="text-uppercase text-end font-weight-bold mb-0">Total: </h5>
          </td>
          <td colspan="2">
            <h5 class="text-uppercase text-start font-weight-bold mb-0">${{$filters.formatNumber(sum_transactions)}}</h5>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="modal" v-if="modalVisible">
      <div class="modal-content">
        <div class="modal-header">
          <h6>Actualizar transacction</h6>
          <button class="close-button" @click="hideModal">×</button>
        </div>
        <div class="modal-body">
          <label>Amount</label>
          <input type="number" class="form-control" v-model="transaction_selected.amount">
          <label>Description</label>
          <input type="text" class="form-control" v-model="transaction_selected.description">
          <label>Reference</label>
          <input type="text" class="form-control" v-model="transaction_selected.reference">
        </div>
        <div class="modal-footer">
          <button class="btn btn-danger w-100" @click="updateTransaction">Update transaction</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {get, post} from "@/utils/httpRequest";

export default {
  name: "index",
  data: () => ({
    user_id: null,
    user: null,
    transactions: {
      total_transactions: 0,
      total_amount: 0,
    },
    array_transactions: [],
    sum_transactions: 0,
    modalVisible: false,
    transaction_selected: {
      _id: '',
      amount: 0,
      description: '',
      reference: '',
    },
    user_configuration: null
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getProfile()
    this.getTransactions()
    this.getUserConfiguration()
  },
  methods: {
    showModal(item) {
      this.transaction_selected = {
        _id: item._id,
        amount: item.amount,
        description: item.description,
        reference: item.reference
      }
      this.modalVisible = true;
    },
    hideModal() {
      this.clearTransaction()
      this.modalVisible = false;
    },
    clearTransaction(){
      this.transaction_selected = {
            _id: '',
            amount: 0,
            description: '',
            reference: '',
      }
    },
    async getProfile(){
      const {data} = await get(`users/find-one?id=${this.user_id}`)
      data.password = ''
      this.user = data
    },

    async getTransactions () {
      const {data} = await get(`traced-capital/by-user?id=${this.user_id}`)
      this.array_transactions = data
      this.sum_transactions = this.array_transactions.reduce((a, b) => a + b.amount, 0)
    },

    async updateUser() {
      await post('users/update', {...this.user, _id: this.user_id})
      await this.getProfile()
      this.$swal({
        html: 'Process successfully',
        icon: 'success',
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
    async createTransactions(){
      if(this.transactions.total_transactions >=1 && this.transactions.total_amount >= 1){
        await post('traced-capital/create', {...this.transactions, _id: this.user_id})
        await this.getTransactions();
        this.transactions = {
          total_transactions: 0,
          total_amount: 0,
        }
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }else{
        this.$swal({
          html: 'Error. Please check again',
          icon: 'error',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    },
    async removeTransaction(id){
      await post('traced-capital/remove', {id: id})
      await this.getTransactions();
      this.$swal({
        html: 'Process successfully',
        icon: 'success',
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
    async updateTransaction(){
      if(this.transaction_selected.amount >= 1 && this.transaction_selected.description !=='' && this.transaction_selected.reference !== ''){
        await post('traced-capital/update', this.transaction_selected)
        this.hideModal()
        await this.getTransactions();
        this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    },
    async getUserConfiguration() {
      const { data } = await get(`auth/get-user-config-by-id?id=${this.user_id}`
      );
      this.user_configuration = data;
    },
    async approveFile(){
      this.$swal({
        html: 'Are you sure you want approve the document?',
        showCancelButton: true,
        confirmButtonText: 'YES',
        cancelButtonText: `NO`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post('user-configurations/approve-by-user-id', {_id: this.user_id})
          window.location.reload()
        }
      })
    },
    async deleteFile(){
      this.$swal({
        html: 'Are you sure you want delete the document?',
        showCancelButton: true,
        confirmButtonText: 'YES',
        cancelButtonText: `NO`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post('user-configurations/delete-by-user-id', {_id: this.user_id})
          window.location.reload()
        }
      })
    }
  }
}
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  width: 400px;
  padding: 20px;
  border-radius: 8px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modal-header h3 {
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #777;
  cursor: pointer;
}

.modal-body {
  margin-bottom: 10px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
